*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.main-div{
  background: rgb(82, 64, 64);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.center-div{
  width: 25%;
  height: 80vh;
  background-color: antiquewhite;
  box-shadow: 5px 5px 25px -5px rgb(0, 0, 0,0.5);
  border-radius: 15px;
}
h1{
  color: rgb(165, 54, 2);
  background: transparent;
  background-color: rgb(213, 173, 113);
  padding: 6px 0 2px 0;
  margin-top: 10px;
  box-shadow: 5px 5px 15px -5px rgb(0, 0, 0,0.3);

}
input{
  text-align: center;
  height: 30px;
  top:10px;
  border: none;
  background: transparent;
  font-size: 20px;
  font-weight: 500;
  width: 60%;
  border-bottom: 2px solid brown;
  outline: none;
}
button{
  min-height: 40px;
  width: 40px;
  border-radius: 50%;
  border-color: transparent;
  background-color:rgb(165, 54, 2) ;
  color:rgb(210, 157, 77) ;
  font-size: 30px;
  border: none;
  outline: none;
  margin-left: 10px;
  box-shadow: 5px 5px 15px -5px rgb(0, 0, 0,0.3);

}
button:hover{
  background-color: gold;
}
ol{
  margin-top: 30px;
}
ol li{
padding-left: 0px;
text-align: left;
font-size: 20px;
font-weight: 500;
min-height: 40px;
display: flex;
align-items: center;
text-transform: capitalize;
color: rgb(165, 54, 2);
}
.todo_style{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}
.todo_style .fa-times{
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:goldenrod;
  border-radius: 50%;
  margin: 0 15px 0 35px;
  color: goldenrod;
  box-shadow: 5px 5px 15px -5px rgb(0, 0, 0,0.3);

}
.todo_style .fa-times:hover{
  background-color: rgb(136, 51, 12);

}
.todo_style .fa-times:hover .list_style{
  color:rgb(165, 54, 2);
  ;
}
@media screen and (max-width:768px) {
  .main-div{
    background: rgb(82, 64, 64);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .center-div{
    width: 20rem;
    height: 30rem;
  }
  
}